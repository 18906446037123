<script setup lang="ts">
import { computed } from "vue";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import i18n from "@/entrypoints/shared/i18n";
import { getTextContrastColor } from "@/entrypoints/shared/contrast_calculator";
import { optionColorClass, optionColorStyle } from "@/entrypoints/shared/handraise_helpers";
import { flattenOptions } from "@/entrypoints/shared/contest_utilities";
import { Locale, HandRaiseVoter, PropType, ConferenceOption } from "@/types";

const props = defineProps({
  voter: {
    type: Object as PropType<HandRaiseVoter>,
    required: true,
  },
  observerMode: {
    type: Boolean,
    default: false,
  },
  fullBackground: {
    type: Boolean,
    default: false,
  },
});

const { t } = i18n.global;

const locale = computed<Locale>(() => i18n.global.locale.value);

const presentationStore = usePresentationStore();

// Only 1 contest per VR is enabled in handraise
const options = computed(() => flattenOptions(presentationStore.contestsInActiveVotingRound[0].options));

const blankAccentColor = computed(() => presentationStore.contestsInActiveVotingRound[0].blankOptionColor || null);

const optionColors = computed(() => {
  const colors = {};
  options.value.map((option: ConferenceOption) => colors[option.reference] = option.accentColor || null);
  return colors;
});

const votedOptionNames = computed(() => {
  if (!props.voter.voted) return t('js.handraise.voter.not_voted_yet');
  if (!props.voter.optionReferences.length) return t('js.handraise.voter.blank');

  let votedFor = t('js.handraise.voter.voted_for');
  props.voter.optionReferences.forEach(optionReference => {
    const option = options.value.find((option: ConferenceOption) => option.reference === optionReference);
    if (option) votedFor += `<br>• ${option.title[locale.value]}`;
  });

  return votedFor;
});

const tooltipText = computed(() =>
  `${props.voter.name}<br>${t('js.handraise.voter.weight')} ${props.voter.weight}<br> ${votedOptionNames.value}`
);

const voterSingleReference = computed(() => {
  if (!props.voter.voted) return "not_voted";
  if (!props.voter.optionReferences.length) return "blank";
  return props.voter.optionReferences[0];
});
</script>

<template>
  <div
    :class="`card animate
      ${props.observerMode && props.fullBackground ? optionColorClass(voterSingleReference, blankAccentColor) : ''}
      ${props.observerMode && !props.fullBackground ? 'option-color-default' : ''}`"
    :style="props.observerMode && props.fullBackground ? optionColorStyle(options, voterSingleReference, blankAccentColor) : ''"
  >
    <div
      class="d-flex flex-row p-2 h-100 align-items-center justify-content-between"
      :key="tooltipText"
      v-tooltip.html="tooltipText"
    >
      <div
        class="text-truncate"
        :style="`color: ${voter.voted && fullBackground && voter.optionReferences.length
          ? getTextContrastColor(optionColors[voterSingleReference]) : 'black'}`"
      >
        {{ voter.name }}
      </div>
      <div v-if="!observerMode" class="d-flex gap-2">
        <template v-if="voter.voted">
          <div
            v-if="!voter.optionReferences.length"
            :class="`option-color-badge ${optionColorClass(voterSingleReference, blankAccentColor)}`"
            :style="optionColorStyle(options, voterSingleReference, blankAccentColor)"
          />
          <template v-else>
            <div
              v-for="optionReference in voter.optionReferences"
              :class="`option-color-badge ${optionColorClass(optionReference, blankAccentColor)}`"
              :style="optionColorStyle(options, optionReference, blankAccentColor)"
            />
          </template>
        </template>
        <div v-else class="option-color-badge option-color-default" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.animate {
  -webkit-animation: all 1s linear;
  -moz-animation: all 1s linear;
  transition: all 1s linear;
}
</style>
