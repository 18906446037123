<template>
	<div class="dropdown" v-show="availableLocales.length > 1">
		<button class="btn btn-sm btn-light dropdown-toggle border" type="button"id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			{{ $t(`js.language_name`) }}
		</button>
		<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
			<a v-for="locale in availableLocales" :key="`lang_${locale}`" class="dropdown-item" href="#" @click="setLocale(locale)" >
				{{ $t(`js.language_name`, locale) }}
			</a>
		</div>
	</div>
</template>

<script lang="ts">
import { mapActions, mapState } from "pinia";
import { useSharedStore } from "@/entrypoints/stores/shared";

export default {
	name: "LocaleSwitcher",
	computed: {
		...mapState(useSharedStore, {
			availableLocales: "locales",
		}),
		currentLocale() {
			return this.$i18n.locale;
		},
	},
	methods: {
		...mapActions(useSharedStore, ["setLocale"]),
	},
}
</script>
