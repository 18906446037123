<template>
  <div class="option-result col-12" style="overflow-x: auto">
    <horizontal-bars v-if="resultChart === 'horizontal_bars'" :theme="theme" :chart-data="chartData"
      :options="chartOptions" class="option-content w-100 position-relative" />
    <vertical-bars v-else-if="resultChart === 'vertical_bars'" :theme="theme" :chart-data="chartData"
      :options="chartOptions" class="option-content w-100 position-relative"
      :style="{ minWidth: `${Math.max(40, labelledCounts.length * 2)}em` }" />
    <pie-chart v-else-if="resultChart === 'pie'" :theme="theme" :chart-data="chartData" :options="chartOptions"
      class="option-content w-100 position-relative" style="min-width: 40em" />
  </div>
</template>

<script lang="ts">
import HorizontalBars from "./charts/HorizontalBars.vue"
import VerticalBars from "./charts/VerticalBars.vue";
import PieChart from "./charts/PieChart.vue";
import { mapState } from "pinia";
import { flattenOptions } from "@/entrypoints/shared/contest_utilities";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { defineComponent } from "vue";
import type {
  PropType,
  ConferenceOption,
  Theme,
  ResultsCount,
  ResultsRow,
  ResultChartType,
  ResultsData,
  ResultsChartOptions,
} from "@/types";

export default defineComponent({
  name: "NormalResultChart",
  components: { PieChart, VerticalBars, HorizontalBars },
  props: {
    resultData: {
      type: Object as PropType<ResultsData>,
      required: true,
    },
    resultChart: {
      type: String as PropType<ResultChartType>,
      default: "horizontal_bars",
    },
    options: {
      type: Array as PropType<ConferenceOption[]>,
      required: true,
    },
    theme: {
      type: String as PropType<Theme>,
      default: "light",
    },
    chartOptions: {
      type: Object as PropType<ResultsChartOptions>,
      default: () => ({}),
    },
  },
  methods: {
    flattenOptions,
    truncateLabel(label: string, maxLength: number = 110, ending: string = "…") {
      return label.length > maxLength
        ? label.slice(0, maxLength) + ending
        : label;
    },
  },
  computed: {
    ...mapState(useSharedStore, ["firstAvailableLocale"]),
    labelledCounts() {
      return this.resultData.optionSortedCounts.map((row: ResultsRow) => {
        const loptions = this.flattenOptions(this.options);
        const option = loptions.find((o: ConferenceOption) => o.reference === row[0]);
        return ({
          label: this.truncateLabel(option.title[this.firstAvailableLocale]
            || Object.values(option.title)[0]
            || "missing translation"),
          count: row[1],
          elected: this.resultData.elected.includes(option.reference),
          tied: this.resultData.tied.includes(option.reference),
          disabled: this.resultData.disabledOptions.includes(option.reference),
        });
      });
    },
    chartData() {
      const colors = this.labelledCounts.map((count: ResultsCount) => {
        if (count.elected) return "#d4edda";
        else if (count.tied) return "#fff3cd";
        else return "#6c757d";
      });
      return {
        labels: this.labelledCounts.map((count: ResultsCount) => count.label),
        datasets: [{
          backgroundColor: colors,
          data: this.labelledCounts.map((count: ResultsCount) => count.count),
        }],
      }
    },
  },
});
</script>
