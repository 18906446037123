<script setup lang="ts">
import { computed, ref } from "vue";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { useHandRaiseStore } from "@/entrypoints/stores/hand_raise";
import HandRaiseVoter from "../shared/HandRaiseVoter.vue";
import i18n from "@/entrypoints/shared/i18n";
import OptionReferences from "./OptionReferences.vue";
import {
  optionColorClass,
  optionColorStyle,
  optionPosition,
  optionTitle,
  orderVotersByName
} from '@/entrypoints/shared/handraise_helpers';
import { flattenOptions } from "@/entrypoints/shared/contest_utilities";
import { onMounted, watch } from "vue";
import { Locale, Modes } from "@/types";

const { t } = i18n.global;

const presentationStore = usePresentationStore();

const handRaiseStore = useHandRaiseStore();

const locale = computed<Locale>(() => i18n.global.locale.value);

const votersByName = computed(() => handRaiseStore.handRaiseResultsByVoterName);

const votersByVote = computed(() => handRaiseStore.handRaiseResultsByVoterVote);

// Only 1 contest per VR is enabled in handraise
const options = computed(() => flattenOptions(presentationStore.contestsInActiveVotingRound[0].options));

const blankAccentColor = computed(() => presentationStore.contestsInActiveVotingRound[0].blankOptionColor);

const overviewMode = ref<Modes>("name");

const isRtl = ref<boolean>(false);

const toggleOverviewMode = (newMode: Modes) => overviewMode.value = newMode;

watch(locale, () => isRtl.value = document.getElementsByTagName('html')[0].dir === 'rtl')

onMounted(() => isRtl.value = document.getElementsByTagName('html')[0].dir === 'rtl');
</script>

<template>
  <div>
    <h4 class="h4 text-center mb-4">{{ t('js.handraise.list_view.overview_of_the_vote') }}</h4>
    <!-- References -->
    <OptionReferences :options="options" />

    <!-- Filters -->
    <p :class="`text-dark fw-light mt-4 mb-1 ${isRtl ? 'text-end' : 'text-start'}`">
      <em>{{ t('js.handraise.list_view.sort_by') }}</em>
    </p>
    <div class="row col-md-8 mb-3">
      <div class="col-6 px-2.5">
        <button
          class="btn btn-light w-100"
          :class="{active: overviewMode === 'name'}"
          @click="toggleOverviewMode('name')"
        >
          {{ t('js.handraise.list_view.by_name') }}
        </button>
      </div>
      <div class="col-6 px-2.5">
        <button
          class="btn btn-light w-100"
          :class="{active: overviewMode === 'vote'}"
          @click="toggleOverviewMode('vote')"
        >
          {{ t('js.handraise.list_view.by_vote') }}
        </button>
      </div>
    </div>

    <!-- Overview by name -->
    <div v-if="overviewMode === 'name'" class="voters-grid">
      <HandRaiseVoter
        v-for="voter in orderVotersByName(votersByName)"
        :key="voter.identifier"
        :voter="voter"
      />
    </div>

    <!-- Overview by vote -->
    <div v-else class="d-flex flex-column">
      <div
        v-for="(optionReference, index) in Object.keys(votersByVote)"
        :key="`accordion-for-${optionReference}`"
        class="accordion mb-3"
        :style="optionPosition(options, optionReference, index)"
        :id="`accordion-${optionReference}`"
      >
        <div class="accordion-item">
          <h2 class="accordion-header" :id="`heading-${optionReference}`">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#collapse-panel-${optionReference}`"
              aria-expanded="false"
              :aria-controls="`collapse-panel-${optionReference}`"
            >
              <div
                class="option-color-badge me-2"
                :class="optionColorClass(optionReference, blankAccentColor)"
                :style="optionColorStyle(options, optionReference, blankAccentColor)"
              />
                {{ optionTitle(options, optionReference, locale) }}
            </button>
          </h2>
          <div
            :id="`collapse-panel-${optionReference}`"
            class="accordion-collapse collapse"
            :aria-labelledby="`heading-${optionReference}`"
            :data-bs-parent="`#accordion-${optionReference}`"
          >
            <div class="accordion-body voters-grid">
              <HandRaiseVoter
                v-for="voter in orderVotersByName(votersByVote[optionReference].votes)"
                :key="voter.identifier"
                :voter="voter"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.accordion-button:not(.collapsed) {
  background-color: #F7F7F7;
}

.voters-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 1rem 1rem;
}
</style>
