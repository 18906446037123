<script setup lang="ts">
import { computed } from "vue";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { useHandRaiseStore } from "@/entrypoints/stores/hand_raise";
import type { Locale, PropType, ConferenceOption } from "@/types";
import i18n from "@/entrypoints/shared/i18n";
import breakpoint from "@/entrypoints/shared/breakpoint";
import {
  optionPosition,
  optionColorStyle,
  optionColorClass,
  optionTitle
} from "@/entrypoints/shared/handraise_helpers";
import { flattenOptions } from "@/entrypoints/shared/contest_utilities";

defineProps({
  options: {
    type: Array as PropType<ConferenceOption[]>,
    required: true,
  },
  observerMode: {
    type: Boolean,
    default: false,
  },
  customStyle: {
    type: String,
    default: null,
  },
});

const { t } = i18n.global;

const presentationStore = usePresentationStore();

const handRaiseStore = useHandRaiseStore();

const locale = computed<Locale>(() => i18n.global.locale.value);

const options = computed<ConferenceOption[]>(() => flattenOptions(presentationStore.contestsInActiveVotingRound[0].options));

const blankAccentColor = computed(() => presentationStore.contestsInActiveVotingRound[0].blankOptionColor);

const showPercentages = computed(() => presentationStore.contestsInActiveVotingRound[0].resultPercentages);

const disregardBlankVotes = computed(() => presentationStore.contestsInActiveVotingRound[0].disregardBlankVotes);

const handRaiseLiveResults = computed(() => handRaiseStore.handRaiseLiveResults);

const maxWidth = computed(() => {
  if (breakpoint.greaterEquals("qhd")) return `max-width: 18rem;`;
  if (breakpoint.greater("lg") && breakpoint.lessEquals("qhd")) return `max-width: 14rem;`;
  return `max-width: 8.5rem;`;
});
</script>

<template>
  <section class="d-flex justify-content-center mt-2 mb-4 gap-3 flex-wrap">
    <div
      v-for="(optionReference, index) in Object.keys(handRaiseLiveResults)"
      :key="`reference-for-${optionReference}`"
      class="d-flex p-2 align-items-center gap-2"
      :class="{ border: observerMode }"
      :style="`${optionPosition(options, optionReference, index)} ${customStyle && observerMode ? customStyle : ''}`"
      v-tooltip="optionTitle(options, optionReference, locale)"
    >
      <div
        :class="`option-color-badge ${optionColorClass(optionReference, blankAccentColor)}`"
        :style="optionColorStyle(options, optionReference, blankAccentColor)"
      />
      <span
        class="text-truncate"
        :style="customStyle && observerMode ? maxWidth : `max-width: 8.5rem;`"
      >
        {{ optionTitle(options, optionReference, locale) }}
      </span>

      <div v-if="observerMode" class="d-flex gap-2 ms-3">
        <span class="text-nowrap fw-bold">
          {{ t("js.handraise.list_view.votes", handRaiseLiveResults[optionReference].results.count) }}
        </span>
        <span v-if="((handRaiseLiveResults[optionReference].results.percentage && optionReference !== 'blank') || (optionReference === 'blank' && !disregardBlankVotes)) && showPercentages" class="text-nowrap fw-bold">
          {{ handRaiseLiveResults[optionReference].results.percentage }}%
        </span>
      </div>
    </div>
  </section>
</template>
